import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import AppContent from "./components/AppContent";
import AppHeader from "./components/AppHeader";
import PageTitle from "./components/PageTitle";
import styles from "./styles/modules/app.module.scss";

function App() {
  const loadItems = async () => {
    let invites = [];
    try {
      const response = await fetch("http://35.192.88.213:8000/api/v1/guests");
      invites = await response.json();
    } catch (e) {}

    const sortInvites = invites.map((e) => ({
      id: e.id,
      status: "incomplete",
      time: "25/08/2023 04:23:15",
      title: `${e.type} ${e.name} | ${e.table}`,
    }));

    window.localStorage.setItem("todoList", JSON.stringify(sortInvites));
  };

  useEffect(() => {
    loadItems();
  }, []);

  return (
    <>
      <div className="container">
        <PageTitle>Tabitha & Aime</PageTitle>
        <div className={styles.app__wrapper}>
          <AppHeader />
          <AppContent />
        </div>
      </div>
    </>
  );
}

export default App;
