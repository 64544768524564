import React from "react";
import { useSelector } from "react-redux";
import styles from "../styles/modules/app.module.scss";
import TodoItem from "./TodoItem";

const container = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};
const child = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

function AppContent() {
  const todoList = useSelector((state) => state.todo.todoList);
  const filterStatus = useSelector((state) => state.todo.filterStatus);
  const filterText = useSelector((state) => state.todo.filter);

  const sortedTodoList = [...todoList];
  sortedTodoList.sort((a, b) => new Date(b.time) - new Date(a.time));

  const filteredTodoList = sortedTodoList.filter((item) => {
    if (filterText.length > 0) {
      return item.title.toLowerCase().includes(filterText.toLowerCase());
    }

    if (filterStatus === "all") {
      return true;
    }

    return item.title.split(" | ")[1] === filterStatus;
  });

  return (
    <div className={styles.content__wrapper}>
      {filteredTodoList && filteredTodoList.length > 0 ? (
        filteredTodoList.map((todo) => (
          // <motion.div key={todo.id} variants={child}>
          <TodoItem key={todo.id} todo={todo} />
          // </motion.div>
        ))
      ) : (
        <p className={styles.emptyText}>No Todos</p>
      )}
    </div>
  );
}

export default AppContent;
