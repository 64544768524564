import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button, { SelectButton } from "./Button";
import styles from "../styles/modules/app.module.scss";
import TodoModal from "./TodoModal";
import { updateFilterStatus, updateFilterText } from "../slices/todoSlice";

function AppHeader() {
  const todoList = useSelector((state) => state.todo.todoList);
  const [modalOpen, setModalOpen] = useState(false);
  const initialFilterStatus = useSelector((state) => state.todo.filterStatus);
  const [filterStatus, setFilterStatus] = useState(initialFilterStatus);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  const tables = [...new Set(todoList?.map((e) => e.title?.split(" | ")[1]))];

  const updateFilter = (e) => {
    setFilterStatus(e.target.value);
    dispatch(updateFilterStatus(e.target.value));
  };

  const onUpdateFilter = (e) => {
    setFilter(e.target.value);
    dispatch(updateFilterText(e.target.value));
  };

  return (
    <div className={styles.appHeader}>
      <div className="form__group field">
        <input
          type="input"
          className="form__field"
          placeholder="Name"
          value={filter}
          onChange={onUpdateFilter}
          name="name"
          id="name"
          required
        />
        <label htmlFor="name" className="form__label">
          Nom
        </label>
      </div>
      <SelectButton
        id="status"
        onChange={(e) => updateFilter(e)}
        value={filterStatus}
      >
        <option value="all">All</option>
        {tables.map((e) => (
          <option key={e} value={e}>
            {e}
          </option>
        ))}
      </SelectButton>
    </div>
  );
}

export default AppHeader;
